<template>
  <a-row :gutter="25" style="border-top: 0.5px solid #bdbdbd">
    <!--{{ "dataForm "  +  JSON.stringify(dataForm.dataTableInfo) }}-->
    <a-col
      :xxl="24"
      :lg="24"
      :md="24"
      :xs="24"
    >
      <sdCards>
        <a-row :gutter="25">
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <span class="field-title" style="font-size: 18px">Formulario dinámico</span>
          </a-col>
        </a-row>
        <br>
        <a-row class="mb-4" :gutter="25" v-for="(section, indexSection) in questions.sections" :key="section">
          <a-col
            :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
            <span class="field-title" style="padding-left: 10px;">{{indexSection == 0 ? "Título del formulario" : "Título de la sección"}}</span><br>
            <span class="field-text-value ml-2" style="padding-left: 2px;">{{section.title}}</span>
          </a-col>
          <a-col
            :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
            <span class="field-title" style="padding-left: 10px;">{{indexSection == 0 ? "Id del formulario" : "Id de la sección"}}</span><br>
            <span class="field-text-value ml-2" style="padding-left: 2px;">{{indexSection == 0 ? dynamic_form.id : section.id}}</span>
          </a-col>
          <a-col :xxl="24" :lg="24" :md="24" :xs="24" class="card-fields mb-4">
            <span class="field-title" style="padding-left: 10px;">{{indexSection == 0 ? "Descripción del formulario" : "Descripción de la sección"}}</span><br>
            <span class="field-text-value ml-2" style="padding-left: 2px;">{{section.description}}</span>
          </a-col>
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <span class="field-title" style="font-size: 18px">{{indexSection == 0 ? "Preguntas del formulario" : "Preguntas de la sección"}}</span>
          </a-col>
          <br><br>
          <a-col :xxl="24" :lg="24" :md="24" :xs="24">
            <a-row :gutter="25" v-for="(question, indexQuestion) in section.questions" :key="question">
              <a-col
                :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
                <span class="field-title" style="padding-left: 10px;">Título de la pregunta</span><br>
                <span class="field-text-value ml-2" style="padding-left: 2px;">{{question.title}}</span>
              </a-col>
              <a-col
                :xxl="12" :lg="12" :md="12" :xs="24" class="card-fields">
                <span class="field-title" style="padding-left: 10px;">Tipo de pregunta</span><br>
                <span class="field-text-value ml-2" style="padding-left: 2px;">
                  <img
                    :src="require(`@/static/img_events/${questionsTypesIcons[question.type]}`)"
                    alt="icon" width="16" height="16" style="margin-right: 0.8rem;"
                  />
                  {{ questionsTypesDic[question.type] }}
                </span>
              </a-col>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" class="card-fields" v-if="['multipleChoice', 'cbx', 'dropDown'].includes(question.type)">
                <span class="field-title" style="padding-left: 10px;">Opciones</span><br>
                <a-row :gutter="25">
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-for="(option) in question.options" :key="option">
                    <span class="field-text-value ml-2" style="padding-left: 2px;">{{option.content}}</span>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :xxl="24" :lg="24" :md="24" :xs="24" class="card-fields">
                <span class="field-title" style="padding-left: 10px;">Descripción de la pregunta</span><br>
                <span class="field-text-value ml-2" style="padding-left: 2px;">{{question.description}}</span>
                <hr v-if="indexQuestion !== section.questions.length - 1" color="#CCCCCC" width="98%" />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </sdCards>
    </a-col>
  </a-row>
</template>
<script>

import * as questionTypes from '@/constants/QuestionTypes.js';

const questionsTypesDic = {
  [questionTypes.shortAnswer]: 'Respuesta breve',
  [questionTypes.largeAnswer]: 'Respuesta larga',
  [questionTypes.multipleChoice]: 'Opción multiple',
  [questionTypes.cbx]: 'Casillas de verificación',
  [questionTypes.dropDown]: 'Lista desplegable',
  [questionTypes.date]: 'Fecha',
  [questionTypes.time]: 'Hora',
  [questionTypes.file]: 'Carga de archivos',
  [questionTypes.matrixTxt]: 'Matriz de texto',
  [questionTypes.cct]: 'CCT',
  [questionTypes.cct_fill]: 'Campo CCT',
}

const questionsTypesIcons = {
  [questionTypes.shortAnswer]: 'SEJ_Eventos_texto corto.svg',
  [questionTypes.largeAnswer]: 'SEJ_Eventos_texto largo.svg',
  [questionTypes.multipleChoice]: 'SEJ_Eventos_opciones.svg',
  [questionTypes.cbx]: 'SEJ_Eventos_casillas de verificacion.svg',
  [questionTypes.dropDown]: 'SEJ_Eventos_lista desplegable.svg',
  [questionTypes.date]: 'SEJ_Eventos_fecha.svg',
  [questionTypes.time]: 'SEJ_Eventos_hora.svg',
  [questionTypes.file]: 'SEJ_Eventos_carga de archivos.svg',
  [questionTypes.matrixTxt]: 'SEJ_Eventos_cuadricula de respuesta.svg',
  [questionTypes.cct]: 'Mi muro_Formularios_CCT.svg',
  [questionTypes.cct_fill]: 'Mi muro_Formularios_CCT.svg',
}

const DynamicForm = {
  name: 'DynamicFormComponent',
  components: {
  },
  props: {
    dynamic_form: Object
  },
  setup(props) {
    const questions = JSON.parse(props.dynamic_form.questions_json);
    return {questions, questionsTypesDic, questionsTypesIcons}
  }
};

export default DynamicForm;
</script>
<style scoped>
.card-title{
  padding: 1rem;
  border-left: 0.5rem solid #3fa7e1;
  background: #f8f8f8;
  border-top: 2px solid #e8e5e5;
  font-weight: bold !important;
}
.card-fields{
  padding: 0 !important;
}
.field-title{
  font-weight: 600 !important;
}
.field-text-value{
  color: #7B868C;
}
</style>
